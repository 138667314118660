<template>
  <div class="content">
    <div class="title">
      <p>注册</p>
    </div>
    <van-form ref="form" :key="formKey">
      <van-cell-group>
        <van-field name="radio" label="">
          <template #input>
            <van-radio-group
              v-model="form.isPerson"
              direction="horizontal"
              @change="changeFn"
            >
              <van-radio :name="false">合作会员（需审核）</van-radio>
              <van-radio :name="true">个人用户</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.name"
          required
          label="公司/团队名称"
          label-width="7.2em"
          placeholder="请输入公司/团队名称"
          :rules="nameRules"
          v-if="form.isPerson === false"
        />
        <van-field
          v-model="form.username"
          required
          label="管理员账号"
          label-width="7.2em"
          placeholder="请输入管理员账号"
          :rules="usernameRules"
        />
        <van-field
          v-model="form.password"
          required
          label="管理员密码"
          label-width="7.2em"
          type="password"
          placeholder="请输入管理员密码"
          :rules="passwordRules"
        />
        <van-field
          v-model="form.conPassword"
          required
          label="确认密码"
          label-width="7.2em"
          type="password"
          placeholder="请输入确认密码"
          :rules="conPasswordRules"
        />
        <van-field
          v-model="form.contactName"
          label="联系人姓名"
          label-width="7.2em"
          placeholder="请输入联系人姓名"
          v-if="form.isPerson === false"
        />
        <van-field
          v-model="form.contactPhone"
          required
          label="联系人电话"
          placeholder="请输入联系人电话"
          :rules="[
            { required: true },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' }
          ]"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          @click="onSubmit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Form, Field, Button } from "vant";
import { mapActions } from "vuex";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button
  },
  data() {
    return {
      formKey: 0,
      test: true,
      form: {
        name: null, // 公司团队名称
        username: null, // 管理员账号
        password: null, // 密码
        conPassword: null, // 确认密码
        contactName: null, // 联系人姓名
        contactPhone: null, // 联系人方式
        isPerson: true // 用户类型
      },
      nameRules: [{ required: true }],
      usernameRules: [
        { required: true },
        {
          validator: value => {
            return value.length > 4 && value.length < 20;
          },
          message: "长度在5-20个字符",
          trigger: "onBlur"
        }
      ],
      passwordRules: [
        { required: true },
        {
          validator: value => {
            return value.length > 5;
          },
          message: "密码长度不低于6位",
          trigger: "onBlur"
        }
      ],
      conPasswordRules: [
        { required: true },
        {
          validator: value => {
            return value === this.form.password;
          },
          message: "确认密码和管理员密码不一致！",
          trigger: "onBlur"
        }
      ]
    };
  },
  methods: {
    ...mapActions("user", ["register"]),
    onSubmit() {
      this.$refs.form
        .validate()
        .then(() => {
          this.register({ ...this.form }).then(res => {
            if (res.data.code === 2000) {
              this.$message.success("注册成功！");
              this.$router.push({
                name: "mobile-login"
              });
            }
          });
        })
        .catch(() => {
          console.log("error");
        });
    },
    changeFn() {
      this.formKey++;
    }
  }
};
</script>
<style scoped>
.content {
  padding: 30px;
}
.title {
  text-align: center;
}
</style>
